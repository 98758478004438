<widget-header [name]="widgetName"
               [editable]="isEditable">

  <widget-menu
    slot="menu"
    [resource]="resource">
  </widget-menu>

</widget-header>
<div class="grid--widget-content">
  <no-results *ngIf="noEntries" 
              [title]="text.noResults">
  </no-results>
  <ul class="widget-box--arrow-links">
    <li class="-widget-box--arrow-multiline" 
        *ngFor="let news of entries">
      <div class="news-project">
        <op-principal
          *ngIf="news.author"
          [principal]="news.author"
          [hideName]="true"
          class="news-author-avatar hidden-for-mobile"
        ></op-principal>
        <div>
          <a [href]="newsProjectPath(news)"
             [textContent]="newsProjectName(news)">
          </a>:
          <a [href]="newsPath(news)"
             [textContent]="news.title">
          </a>
        </div>
        <div *ngIf="news.author" 
             class="news-author">
          <span [innerHTML]="text.addedBy(news)"></span>
        </div>
        <div>
          <p *ngIf="news.summary" 
             [textContent]="news.summary"
             class="widget-box--additional-info"></p>
        </div>
      </div>
    </li>
  </ul>
</div>